import './utils/ajax';
import Form from '../../modules/core/forms/theme/js/Form';
import Utils from '../../modules/utils/utils-js/theme/js/utils/Utils';

declare let Slider;

class Site {
    public static onPageHome(): void {

    }

    public static discover($parent?: JQuery): void {
        Utils.updateElements($parent);
    }

    private static updateFloat() {
        var $header=$('.header');

        var top=$(window).scrollTop();
        var wh=$(window).height();

        $('body').toggleClass('body--float', top > 0);
        $('body').toggleClass('body--no-float', top == 0);
        $('body').toggleClass('body--float-100', top > wh);
        $('body').toggleClass('body--float-ac', top > wh - 130);
    }

    private static initHtml() {
        var $mainmenu=$('.mainmenu');

        $('.menubutton', $mainmenu).click(function(e) {
            e.preventDefault();

            $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
            $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
        });

        this.discover();
    }

    private static initRwd() {
        var $header = $('.header');
        $header.addClass('header--noanim');

        $(window).resize(Site.updateFloat);
        $(window).scroll(Site.updateFloat);

        Site.updateFloat();

        $header.removeClass('header--noanim');
    }

    public static init() {
        (<any> window).Site = Site;

        Form.initModule();

        $(document).ready(() => {
            Site.initHtml();
            Site.initRwd();
        });

        $(window).on('load', () => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').trigger('pageloaded');
            }, 1000);
        });
    }
}

Site.init();
